.MuiInputBase-root,
.MuiInputLabel-formControl,
.MuiListItemText-root {
  font-size: 0.9rem !important;
}

.MuiCardHeader-root {
  padding: 16px 16px 0px !important;
  margin-bottom: 0px !important;
}

.MuiCardContent-root:last-child {
  padding: 16px !important;
}

.MuiCardContent-root {
  padding: 16px !important;
}

.typography-menu {
  font-size: 1.2rem !important;
  margin-bottom: 10px !important;
}

.MuiAccordionSummary-root.Mui-expanded {
  min-height: 40px !important;
}

.MuiAccordionSummary-root {
  min-height: 40px !important;
}

.MuiAccordionSummary-content.Mui-expanded {
  margin: 8px !important;
}

.MuiTableCell-root:first-of-type {
  box-shadow: inset 0 0 0 #fff !important;
}
.MuiTableCell-root:last-of-type {
  box-shadow: inset 0 0 0 #fff !important;
}

.MuiListSubheader-root {
  margin-top: 14px !important;
  margin-bottom: 8px !important;
}

.css-14qgfqq {
  padding-top: 14px !important;
  padding-bottom: 14px !important;
}

.css-12moesw {
  margin-bottom: 0px !important;
}

/*.css-1e4fcps-MuiTableCell-root {
  padding: 4px !important;
  height: 30px !important;
}

.css-8p48ye-MuiButtonBase-root-MuiIconButton-root-MuiCheckbox-root {
  padding: 0px !important;
} */

.MuiBox-root .MuiList-root:not(.list-attachment) .MuiListItem-root {
  height: 35px !important;
}

.MuiBox-root .MuiList-root:not(.list-attachment) .MuiListItem-root.MuiListItem-gutters {
  height: 25px !important;
  padding-top: 2px !important;
  padding-bottom: 2px !important;
}
/* .MuiPaper-root .MuiList-root .MuiListItem-root {
  height: 30px !important;
} */

.MuiTableCell-root .MuiIconButton-root,
.MuiTableCell-root .MuiSvgIcon-root {
  margin-top: 0px !important;
  padding: 0px !important;
}

.MuiTableCell-body {
  padding: 8px !important;
  border: 1px solid rgba(145, 158, 171, 0.32);
}

.MuiTableCell-head {
  border: 1px solid rgba(145, 158, 171, 0.32);
}

.MuiTableCell-root:first-of-type {
  /* border-top-left-radius: 0px !important; */
  border-bottom-left-radius: 0px !important;
}

.MuiTableCell-root:last-of-type {
  border-bottom-right-radius: 0px !important;
}

.MuiButton-startIcon {
  margin-right: 4px !important;
}

.MuiDialogTitle-root {
  padding: 14px 14px 0px !important;
}

.MuiDialogContent-root,
.MuiDialogActions-root {
  padding: 14px !important;
}

/* .css-lkkapo-MuiFormLabel-root-MuiInputLabel-root {
  transform: translate(14px, 8px) scale(1) !important;
} */
.MuiAutocomplete-root .MuiFormControl-root .MuiFormLabel-root:not(.MuiInputLabel-shrink) {
  transform: translate(14px, 8px) scale(1) !important;
}

.MuiInputBase-input {
  padding: 7px 10px !important;
}

.MuiInputBase-input.MuiAutocomplete-input {
  padding: 0px !important;
}

.MuiFormHelperText-root {
  margin-top: 0px !important;
}

/* .css-4hqp1a-MuiContainer-root {
  padding-left: 16px !important;
  padding-right: 16px !important;
} */

@media (min-width: 1280px) {
  .css-ict6bo {
    padding-top: 54px !important;
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
}

.main-style {
  padding-top: 54px !important;
  padding-bottom: 16px !important;
  padding-left: 0px !important;
}

@media (min-width: 1280px) {
  .MuiAppBar-root .MuiToolbar-root {
    min-height: 54px !important;
    padding-left: 24px !important;
    padding-right: 24px !important;
  }
}

@media (min-width: 600px) {
  .MuiAppBar-root .MuiToolbar-root {
    min-height: 54px !important;
  }
}

.MuiListSubheader-root,
.MuiButtonBase-root.MuiListItem-root {
  padding-left: 24px !important;
}

.ant-table-thead .ant-table-cell {
  box-shadow: inset 0 0 0 #fff !important;
  background-color: #f4f6f8 !important;
  color: #637381 !important;

  font-weight: 600 !important;
}

.ant-table-cell {
  font-size: 0.9rem !important;
  font-family: Public Sans, sans-serif !important;
}

.ant-table-container,
.ant-table-bordered,
.ant-table-header {
  border-top-left-radius: 8px !important;
  border-top-right-radius: 8px !important;
}

.ant-table-container table > thead > tr:first-child th:first-child {
  border-top-left-radius: 8px !important;
}

.ant-table-container table > thead > tr:first-child th:last-child {
  border-top-right-radius: 8px !important;
}

.ant-table-content table tr th,
.ant-table-content table tr:not(.ant-table-measure-row) td,
.ant-table-container table tr th,
.ant-table-container table tr:not(.ant-table-measure-row) td {
  border: 1px solid rgba(145, 158, 171, 0.32) !important;
}

.ant-table.ant-table-small .ant-table-title,
.ant-table.ant-table-small .ant-table-footer,
.ant-table.ant-table-small .ant-table-thead > tr > th,
.ant-table.ant-table-small .ant-table-tbody > tr:not(.ant-table-measure-row) > td,
.ant-table.ant-table-small tfoot > tr > th,
.ant-table.ant-table-small tfoot > tr > td {
  padding-top: 4px !important;
  /* padding-left: 8px !important; */
  padding-bottom: 4px !important;
  /* padding-right: 8px !important; */
}

/* .css-17ennvg-MuiButtonBase-root-MuiChip-root {
  height: 25px !important;
} */

.MuiAutocomplete-root input {
  margin-left: 4px !important;
}

/* .ant-table-measure-row {
  display: block !important;
} */

.antdTable-setting .MuiListItemSecondaryAction-root .MuiButtonBase-root {
  padding: 4px !important;
}

.ant-table-thead .ant-table-cell {
  text-align: center !important;
}

input {
  font-size: 0.9rem !important;
}

/* fieldset.MuiOutlinedInput-notchedOutline > legend {
  font-size: 0.7rem !important;
} */

.MuiAutocomplete-root .MuiButtonBase-root.MuiChip-root {
  height: 20px !important;
}

.MuiAutocomplete-root .MuiChip-label {
  font-size: 0.9rem !important;
}

.MuiAutocomplete-listbox li {
  font-size: 0.9rem !important;
}

.MuiButton-root {
  font-size: 0.8rem !important;
}

.MuiAccordionSummary-root h6 {
  font-size: 0.9rem !important;
}

.MuiAutocomplete-root .MuiOutlinedInput-root {
  padding-top: 7px !important;
  padding-bottom: 7px !important;
}

.MuiPaper-root-MuiAutocomplete-paper {
  font-size: 0.9rem !important;
}

.ant-select,
.ant-select-item,
.ant-pagination {
  font-size: 0.9rem !important;
}

.MuiListItemText-root .MuiTypography-root {
  font-size: 0.9rem !important;
}

.MuiListSubheader-root {
  font-size: 0.9rem !important;
  line-height: 32px !important;
}

.ant-table .MuiSvgIcon-root {
  width: 0.8em !important;
  height: 0.8em !important;
}

.ant-table-row-expand-icon {
  float: unset;
}

.MuiTypography-root {
  font-size: 0.9rem !important;
}

h2.MuiTypography-root {
  font-size: 1.2rem !important;
}

h1.MuiTypography-root {
  font-size: 1.3rem !important;
}

.MuiFormHelperText-root {
  font-size: 0.7rem !important;
}

.MuiList-root {
  min-width: 250px !important;
}

.ant-table-cell .MuiFab-label .MuiSvgIcon-root {
  width: 0.8em !important;
  height: 0.8em !important;
}

.ant-table-cell .MuiFab-root {
  min-height: 25px !important;
  width: 25px !important;
  height: 25px !important;
  margin-right: 4px !important;
}

.ant-table-pagination.ant-pagination {
  margin: 8px 0px !important;
}

.MuiContainer-root .MuiPaper-root {
  border: 1px solid #f1f1f1 !important;
}

.MuiMenu-paper > ul > li {
  font-size: 0.9rem !important;
  padding-top: 4px !important;
  padding-bottom: 4px !important;
}

/* .MuiGrid-item {
  padding-top: 20px !important;
  padding-left: 20px !important;
} */

legend {
  width: unset;
}

.tab-no-margin .MuiButtonBase-root {
  margin-right: 0px !important;
}

.MuiTabs-flexContainer .MuiTab-root {
  margin-right: unset;
  padding-left: 30px;
  padding-right: 30px;
}

[class*='MuiDateRangePickerInput-root'] .MuiFormControl-root .MuiInputLabel-root:not(.MuiInputLabel-shrink),
.MuiInputLabel-formControl:not(.MuiInputLabel-shrink) {
  transform: translate(14px, 9px) scale(1);
}

.MuiTableCell-body .MuiFab-label .MuiSvgIcon-root {
  width: 0.8em !important;
  height: 0.8em !important;
}

.MuiTableCell-body .MuiFab-root {
  min-height: 25px !important;
  width: 25px !important;
  height: 25px !important;
  margin-right: 4px !important;
}

.MuiTableCell-body {
  font-size: 0.9rem !important;
  /* font-family: Public Sans, sans-serif !important; */
  padding-top: 6px !important;
  padding-bottom: 6px !important;
}

.MuiTableCell-body .MuiButton-root {
  margin-top: -5px;
  padding-top: 4px;
  padding-bottom: 4px;
}

/* .ant-table-thead .ant-table-cell {
  box-shadow: inset 0 0 0 #fff !important;
  background-color: #f4f6f8 !important;
  color: #637381 !important;

  font-weight: 600 !important;
}



.ant-table-container table > thead > tr:first-child th:first-child {
  border-top-left-radius: 8px !important;
}

.ant-table-container table > thead > tr:first-child th:last-child {
  border-top-right-radius: 8px !important;
}

.ant-table-content table tr th,
.ant-table-content table tr:not(.ant-table-measure-row) td,
.ant-table-container table tr th,
.ant-table-container table tr:not(.ant-table-measure-row) td {
  border: 1px solid rgba(145, 158, 171, 0.32) !important;
}

.ant-table.ant-table-small .ant-table-title,
.ant-table.ant-table-small .ant-table-footer,
.ant-table.ant-table-small .ant-table-thead > tr > th,
.ant-table.ant-table-small .ant-table-tbody > tr:not(.ant-table-measure-row) > td,
.ant-table.ant-table-small tfoot > tr > th,
.ant-table.ant-table-small tfoot > tr > td {
  padding-top: 4px !important;
  padding-bottom: 4px !important;
} 

.ant-table-thead .ant-table-cell {
  text-align: center !important;
}
*/
.MuiButton-sizeSmall {
  padding: 4px 10px !important;
}

.grid-container-text .MuiGrid-item {
  padding-top: 16px !important;
}

.ant-table-thead .virtual-table-cell {
  box-shadow: inset 0 0 0 #fff !important;
  background-color: #f4f6f8 !important;
  color: #637381 !important;

  font-weight: 600 !important;
}

.virtual-table-cell {
  font-size: 0.9rem !important;
  font-family: Public Sans, sans-serif !important;
}

.virtual-table-cell .MuiFab-label .MuiSvgIcon-root {
  width: 0.8em !important;
  height: 0.8em !important;
}

.virtual-table-cell .MuiFab-root {
  min-height: 25px !important;
  width: 25px !important;
  height: 25px !important;
  margin-right: 4px !important;
}
.virtual-table-cell {
  border: 1px solid rgba(145, 158, 171, 0.32) !important;
  padding-top: 4px !important;
  padding-left: 8px !important;
  padding-bottom: 4px !important;
  padding-right: 8px !important;
}

.virtual-table-cell.text-center {
  text-align: center !important;
}

.text-right input {
  text-align: right;
}

.row-head td {
  background-color: #00e7ff;
}

.row-subhead td {
  background-color: #b4f1f7;
}

.row-group td {
  background-color: #dfdfdf;
  font-weight: 700;
}

.row-cn td {
  background-color: #f4f4f4;
}

.reject-doc {
  background-color: #ffcccc !important;
  /* color: blue !important; */
}

.reject-doc .ant-table-cell-fix-left,
.ant-table-cell-fix-right {
  background-color: #ffcccc !important;
}

.ant-table-tbody > tr.reject-doc.ant-table-row:hover > td {
  background-color: #ffcccc !important;
}

.mfab-operation .MuiFab-label .MuiSvgIcon-root {
  width: 0.8em !important;
  height: 0.8em !important;
}

.mfab-operation.MuiFab-root {
  margin-top: 5px !important;
  min-height: 25px !important;
  width: 25px !important;
  height: 25px !important;
  /* margin-right: 4px !important; */
}

.Mui-disabled {
  background-color: #f0f0f0;
}

.MuiStep-root .Mui-disabled {
  background-color: unset;
}

.ant-image-preview-mask {
  z-index: 5000;
}
.ant-image-preview-wrap {
  z-index: 5080;
}
.ant-image-preview-img,
.ant-image-img {
  display: unset;
}

.MuiBox-root .MuiList-root .MuiListItem-root.MuiListItem-gutters.MuiListItem-secondaryAction {
  height: unset !important;
}

.ant-table-thead .ant-table-cell.baht-rai,
.ant-table-tbody .ant-table-cell.baht-rai {
  background-color: rgb(208, 236, 231) !important;
}

.baht-rai .MuiInputBase-root {
  background-color: #ffffff !important;
}

.ant-table-summary {
  background-color: #f0f0f0;
}

.ant-table-summary .ant-table-cell-fix-left,
.ant-table-summary .ant-table-cell-fix-right {
  background-color: #f0f0f0;
}

.font-weight-600 {
  font-weight: 600;
}

.text-right {
  text-align: right;
}

.typography-left {
  margin-top: 8px;
  display: block;
}

fieldset .MuiTypography-root {
  font-size: 0.8rem !important;
}

.MuiInputBase-multiline {
  padding: 0px !important;
}

.font-red {
  color: #df0000;
}

.fab-search {
  padding: 0px;
}

.fab-search svg {
  margin-right: unset;
}

.ant-cascader-input {
  border-radius: 8px;
}

.ant-cascader-menus ul,
.ant-cascader-menus ol {
  max-width: 250px;
}

.ant-cascader-menu-item {
  white-space: break-spaces;
  text-overflow: unset;
}

.list-attachment li {
  height: unset !important;
}

.list-attachment .MuiTypography-root {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.cascader-error .ant-input {
  border-color: #ff4842;
}

.row-status,
.row-year,
.row-status .ant-table-cell-fix-left,
.row-year .ant-table-cell-fix-left,
.row-status .ant-table-cell-fix-right,
.row-year .ant-table-cell-fix-right {
  background-color: rgba(0, 171, 85, 0.3);
  color: black;
}

.row-activity-type,
.row-activity-type .ant-table-cell-fix-left,
.row-activity-type .ant-table-cell-fix-right {
  background-color: lightskyblue;
  color: black;
}

.row-activity,
.row-assignment,
.row-activity .ant-table-cell-fix-left,
.row-assignment .ant-table-cell-fix-left,
.row-activity .ant-table-cell-fix-right,
.row-assignment .ant-table-cell-fix-right {
  background-color: rgb(196, 196, 196);
  color: black;
}

.MuiTypography-h3 {
  font-size: 4rem !important;
}

.MuiTypography-h4 {
  font-size: 3rem !important;
}

.MuiTypography-status {
  font-family: Public Sans, sans-serif;
  font-weight: 700;
  font-size: 2rem !important;
}

.fc .fc-popover {
  z-index: 1299 !important;
}

.ant-upload-list-item-name {
  cursor: pointer;
}

.fair-value-content-1 {
  margin-top: 10px;
  font-weight: 800;
  border: 1px solid;
  border-top: none;
  border-left: none;
  border-right: none;
}

.fair-value-content-2 {
  font-weight: 800;
  border-bottom-style: double !important;
}

.fair-value-content-3 {
  margin-top: 20px;
  font-weight: 800;
  text-align: center;
  background-color: lightgrey;
  padding-top: 0px !important;
}

.react-multi-email input {
  border: 1px solid #dfe3e8;
  color: #212b36;
}

.react-multi-email {
  border-radius: 8px !important;
  padding: 10px 14px !important;
}

.react-multi-email.focused {
  border: 2px solid #00ab55 !important;
}
